import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import clsx from 'clsx';
import config from '../../config'
import css from './BookNowBanner.module.css';

class BookNowBanner extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { show: false , height: 0};
    this.onCloseBanner = this.onCloseBanner.bind(this);
    this.saveBookNowBanner = this.saveBookNowBanner.bind(this);
    this.bannerRef = React.createRef();
  }

  componentDidMount() {
    const cookies = document.cookie.split('; ').reduce((acc, c) => {
      const [name, value] = c.split('=');
      return { ...acc, [name]: decodeURIComponent(value) };
    }, {});

    if (cookies.BookingBanner !== '1') {
      this.setState({ show: true });
    }
  }

  componentDidUpdate(previousState){
    // compare the components previous state with the current state
    if(Object.entries(previousState).toString() !== Object.entries(this.state).toString()) {
      const bannerHeight = this.bannerRef.current ? this.bannerRef.current.scrollHeight : 0
      this.setState({
        height: bannerHeight
      })
    }
  }

  onCloseBanner() {
    this.saveBookNowBanner();
    this.setState({ show: false, height: 0 });
  }

  saveBookNowBanner() {
    // We create date object and modify it to show date 1 day into the future.
    let expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + 1);
    // Save the cookie with expiration date
    document.cookie = 'BookingBanner=1; path=/; expires=' + expirationDate.toGMTString();
  }

  render() {
    const { className, rootClassName } = this.props;
    const isServer = typeof window === 'undefined';
    const showbanner = config.showBookingBanner;

    // Server side doesn't store cookies
    if (isServer || !this.state.show || !showbanner) {
      return null;
    } else {

      return (
        <div className={css.contentParent} ref={this.bannerRef} style={{height: this.state.height + "px"}}>
          <div className={css.content}>
            <div className={css.message}>
              <FormattedMessage id="BookNowBanner.message" />
            </div>
            <button className={css.closeBtn} onClick={this.onCloseBanner}>
              <FormattedMessage id="BookNowBanner.closeBtn" />
            </button>
          </div>
        </div>
      );
    }
  }
}

const { string } = PropTypes;

BookNowBanner.defaultProps = {
  className: null,
  rootClassName: null,
};

BookNowBanner.propTypes = {
  className: string,
  rootClassName: string,
};

export default BookNowBanner;
